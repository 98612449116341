import React from "react"
import Layout from "../components/layout"
import TeamTabs from "../components/TeamTabs"
import { Container, Row, Col } from "react-bootstrap"
import { Link } from "gatsby"
import AboutSwiper from "../components/swiper/aboutSwiper"
import Swiper from "react-id-swiper"
import "react-id-swiper/lib/styles/css/swiper.css"
import { LazyLoadComponent } from "react-lazy-load-image-component"

const perksSwiper = {
  slidesPerView: 1,
  loop: true,
  speed: 700,
  pagination: {
    el: ".swiper-pagination",
    type: "bullets",
    clickable: true,
  },
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
}

const AboutUs = ({ data }) => {
  const testimonials = data.allSanityTestimonials.nodes
  return (
    <Layout>
      <div className="about-us-page">
        <div>
          <TeamTabs />
        </div>
        <Container fluid className="join-us">
          <Container className="join-us-inner">
            <h3 className="mr-4 text-white my-0">
              Come Join our Recruiting Team!
            </h3>{" "}
            <Link
              to="/contact-us"
              className="white-btn border-none"
              type="submit"
            >
              JOIN US
            </Link>
          </Container>
        </Container>

        <Container fluid>
          <Row className="flex-column-reverse flex-lg-row">
            <Col
              lg="6"
              className="why-work-content d-flex justify-content-center align-items-center"
            >
              <div
                className=""
                style={{ maxWidth: "500px", padding: "150px 0" }}
              >
                <h3 className="work-flair mb-5">WHY WORK WITH US?</h3>
                <p>
                  As a company where our product is people, we strive to foster
                  a culture that enables our internal team to be the best that
                  they can be. We are a group of excellent recruiters that works
                  as a true team while helping each other succeed. We have a
                  diverse nationwide client base that always keeps us busy, as
                  well as a support staff that allows you to focus your time and
                  efforts on doing what you do best. If you want to join a
                  high-performing recruiting team, contact us today!
                </p>
              </div>
            </Col>
            <Col lg="6" className="why-work-bg"></Col>
          </Row>
          <Row>
            <Col lg="6" className="our-perks-bg p-0">
              <div className="perks-swiper-wrapper">
                <LazyLoadComponent>
                  <Swiper {...perksSwiper}>
                    <div className="perks-slide perks-1"></div>
                    <div className="perks-slide perks-2"></div>
                    <div className="perks-slide perks-3"></div>
                    <div className="perks-slide perks-4"></div>
                    <div className="perks-slide perks-5"></div>
                    <div className="perks-slide perks-6"></div>
                    <div className="perks-slide perks-7"></div>
                    <div className="perks-slide perks-8"></div>
                  </Swiper>
                </LazyLoadComponent>
              </div>
            </Col>
            <Col
              lg="6"
              className="our-perks-content d-flex justify-content-center align-items-center"
              style={{
                backgroundColor: "#0D2E4F",
              }}
            >
              <div
                className=""
                style={{
                  backgroundColor: "#0D2E4F",
                  maxWidth: "500px",
                  padding: "150px 0",
                }}
              >
                <h3 className="perks-flair mb-5" style={{ color: "#00C1C8" }}>
                  OUR PERKS
                </h3>
                <p className="text-light">
                  Our Class A Denver office takes up the entire top level of the
                  building and comes with an onsite gym, fully stocked snack
                  center, a shuffleboard table, and unlimited Cheez-Its! We
                  offer a full line of health, dental, and vision benefits as
                  well as a 401(k) match, Amazon recognition awards, free
                  Netflix, and out early on Fridays.
                </p>
              </div>
            </Col>
          </Row>
          <Row className="flex-column-reverse flex-lg-row">
            <Col
              lg="6"
              className="why-work-content d-flex justify-content-center align-items-center"
            >
              <div
                className=""
                style={{ maxWidth: "500px", padding: "70px 0" }}
              >
                <h3 className="work-flair mb-5">ABOUT OUR OWNERSHIP GROUP</h3>
                <p>
                  Our ownership is made up of a group of seasoned entrepreneurs
                  that have collectively launched well over 30 companies during
                  their careers. Co-Founder John Snellings is a 20+ year veteran
                  of the staffing and recruiting industry having launched,
                  acquired and sold various companies over the years. He and
                  fellow Co-Founder John Smith are owner/partners in not only
                  PeerSource, but also in several Nextaff healthcare staffing
                  firms around the US and their own talent development company
                  (think a mix of Incubator / Service Delivery for recruitment)
                  called PSN Staffing.
                </p>
                <p>
                  John & John, now each on the backside of 40 years old, have a
                  goal to identify, fund (the majority), and help mentor other
                  would-be staffing entrepreneurs specifically in healthcare. In
                  addition to helping PeerSource become an Inc. 5000 “Fastest
                  Growing” private company 5 times, John Snellings/PSN Staffing
                  opened 4 new Nextaff locations around the US in 2021 and plan
                  to continue their expansion as quickly as they find qualified
                  partners. We’re always looking for Talent and Leadership, what
                  our founders affectionately call “DRIVERS", to help them
                  expand their focus in the staffing marketplace. Feel free to
                  contact us directly if you or someone you know might be a good
                  candidate.
                </p>
              </div>
            </Col>
            <Col lg="6" className="ownership-bg"></Col>
          </Row>
        </Container>

        <Container
          fluid
          style={{ backgroundColor: "#0088A0", padding: "100px 0" }}
        >
          <Container>
            <Row>
              <Col md={{ size: 12 }}>
                <div className="bg-tp">
                  <LazyLoadComponent>
                    <AboutSwiper slidesperview={3}>
                      {testimonials.map((test, index) => (
                        <div className="choose-review">
                          <div style={{ position: "relative" }}>
                            <i
                              style={{
                                position: "absolute",
                                fontSize: "90px",
                              }}
                              className="fa fa-quote-left"
                            ></i>
                            <div
                              className="text-right"
                              style={{ color: "#FFBA30" }}
                            >
                              <i class="fa fa-star mr-1"></i>
                              <i class="fa fa-star mr-1"></i>
                              <i class="fa fa-star mr-1"></i>
                              <i class="fa fa-star mr-1"></i>
                              <i class="fa fa-star mr-1"></i>
                            </div>
                            <p
                              style={{
                                position: "relative",
                                marginBottom: "4em",
                              }}
                            >
                              {test.Message}
                            </p>
                            <i
                              style={{ position: "absolute", right: "0" }}
                              className="fa fa-quote-right"
                            ></i>
                          </div>
                          <h6>
                            {test.name}
                            <br />
                            <span className="font-weight-normal">
                              <small>{test.designation}</small>
                            </span>
                          </h6>
                        </div>
                      ))}
                    </AboutSwiper>
                  </LazyLoadComponent>
                </div>
              </Col>
            </Row>
          </Container>
        </Container>
        <Container
          fluid
          className="learn-more-section"
          style={{ backgroundColor: "#0088A0", padding: "100px 0" }}
        >
          <Container>
            <Row>
              <Col lg="6">
                <h3 className="mb-3">Want to learn more?</h3>
                <h5>
                  <em>
                    Feel free to get in touch to learn more about PeerSource!
                  </em>
                </h5>

                <a href="tel:303-867-1111" style={{ fontSize: "20px" }}>
                  303-867-1111
                </a>
                <br />
                <a
                  href="mailto:jobs@mypeersource.com"
                  style={{ fontSize: "20px" }}
                >
                  jobs@mypeersource.com
                </a>
              </Col>
            </Row>
          </Container>
        </Container>
      </div>
    </Layout>
  )
}

export default AboutUs

export const testimonialsQuery = graphql`
  query testimonialsQuery {
    allSanityTestimonials(sort: { fields: order, order: ASC }) {
      nodes {
        id
        name
        rating
        Message
        designation
      }
    }
  }
`
