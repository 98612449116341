import React, { useState } from "react"
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from "reactstrap"
import classnames from "classnames"

// Team Pics
import staffJohn from "../images/aboutus/team/staff-john.png"
import staffJohn1 from "../images/aboutus/team/staff-john-1.png"
import staffJohn2 from "../images/aboutus/team/staff-john-2.png"
import staffJohn3 from "../images/aboutus/team/staff-tervor.png"
import staffJohn4 from "../images/aboutus/team/staff-john-4.png"
// import staffJohn5 from "../images/aboutus/team/staff-john-5.png"
import staffJohn6 from "../images/aboutus/team/chandra.png"
import staffJohn7 from "../images/aboutus/team/staff-chris.png"
// import staffJohn8 from "../images/aboutus/team/staff-john-8.png"
// import staffJohn9 from "../images/aboutus/team/staff-john-9.png"
import staffJohn10 from "../images/aboutus/team/staff-john-10.png"
import staffJohn11 from "../images/aboutus/team/staff-melissa.png"
import staffJohn12 from "../images/aboutus/team/staff-karrie.png"
import staffJohn13 from "../images/aboutus/team/staff-kylie.png"
import staffJohn14 from "../images/aboutus/team/cynthia.png"
// import staffJohn15 from "../images/aboutus/team/staff-jamie.png"
import staffJohn16 from "../images/aboutus/team/staff-franco.png"
// import staffJohn17 from "../images/aboutus/team/lauren.png"
// import staffJohn18 from "../images/aboutus/team/lauren300.png"
import stafflisa from "../images/aboutus/team/llisa-hundley.png"
import staffjason from "../images/aboutus/team/Jason-Muilenburg.png"
// import staffjeannie from "../images/aboutus/team/avatar61.png"
// import stafftaryn from "../images/aboutus/team/stafftaryn.png"
import staffjonathan from "../images/aboutus/team/staff_jonathan.png"

import staffjay from "../images/aboutus/team/jay.png"
import staffdavid from "../images/aboutus/team/david.png"
import staffrob from "../images/aboutus/team/Rob.png"
import staffcarson from "../images/aboutus/team/carson_s.png"
import staffcaitlin from "../images/aboutus/team/caitlin_s.png"
// import saraimg from "../images/aboutus/team/sarah.png"

//Team Contact Icon
import Email from "../images/aboutus/mail.png"
import Linkedin from "../images/aboutus/linkedin.png"
import trevor from "../images/aboutus/team/trevor.png"
import mellisa from "../images/aboutus/team/mellisa.png"
import cris from "../images/aboutus/team/cris.png"
import karrie from "../images/aboutus/team/karrie.png"
import kylie from "../images/aboutus/team/kylie.png"
import cyntia from "../images/aboutus/team/centia300.png"
import chandra from "../images/aboutus/team/chandra.png"
import jamie from "../images/aboutus/team/jamie.png"

const Example = props => {
  const [activeTab, setActiveTab] = useState("1")

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  return (
    <div className="about-us-tabs">
      <div className="container-fluid" style={{ backgroundColor: "#F9F9F9" }}>
        <div
          className="container"
          style={{ paddingTop: "200px", paddingBottom: "100px" }}
        >
          <h1 className="text-center team-flair mb-5 text-dark"> OUR TEAM </h1>
          <Nav
            tabs
            className="d-flex justify-content-center align-items-center"
          >
            <NavItem className="m-3">
              <NavLink
                className={classnames({ active: activeTab === "1" })}
                onClick={() => {
                  toggle("1")
                }}
              >
                {" "}
                <a href="#team-desc">
                  <img alt="" className="team-portrait" src={staffJohn} />{" "}
                </a>{" "}
              </NavLink>{" "}
              <div className="team-info text-center mt-3">
                <h5 className="mb-0"> John Snellings </h5>{" "}
                <p> CEO/Co-founder </p>{" "}
              </div>{" "}
            </NavItem>{" "}
            {/* <NavItem className="m-3">
                          <NavLink
                            className={classnames({ active: activeTab === "1" })}
                            onClick={() => {
                              toggle("1")
                            }}
                          >
                            <div>
                              <a href="#team-desc">
                                <img alt="" className="team-portrait" src={staffJohn} />
                              </a>
                            </div>
                          </NavLink>
                          <div className="team-info text-center mt-3">
                            <h5 className="mb-0">John Snellings</h5>
                            <p>CEO/Co-founder</p>
                          </div>
                        </NavItem> */}
            <NavItem className="m-3">
              <NavLink
                className={classnames({ active: activeTab === "2" })}
                onClick={() => {
                  toggle("2")
                }}
              >
                {" "}
                <a href="#team-desc">
                  <img alt="" className="team-portrait" src={staffJohn1} />{" "}
                </a>{" "}
              </NavLink>{" "}
              <div className="team-info text-center mt-3">
                <h5 className="mb-0"> John Smith </h5> <p> Co-founder </p>{" "}
              </div>{" "}
            </NavItem>
            <NavItem className="m-3">
              <NavLink
                className={classnames({ active: activeTab === "3" })}
                onClick={() => {
                  toggle("3")
                }}
              >
                <a href="#team-desc">
                  {" "}
                  <img alt="" className="team-portrait" src={staffJohn2} />{" "}
                </a>{" "}
              </NavLink>{" "}
              <div className="team-info text-center mt-3">
                <h5 className="mb-0"> Cory Blackwood </h5> <p> CFO </p>{" "}
              </div>{" "}
            </NavItem>
            <NavItem className="m-3">
              <NavLink
                className={classnames({ active: activeTab === "4" })}
                onClick={() => {
                  toggle("4")
                }}
              >
                <a href="#team-desc">
                  {" "}
                  <img alt="" className="team-portrait" src={staffJohn3} />{" "}
                </a>{" "}
              </NavLink>{" "}
              <div className="team-info text-center mt-3">
                <h5 className="mb-0"> Trevor Richards </h5>{" "}
                <p> Director of Client Services </p>{" "}
              </div>{" "}
            </NavItem>
            <NavItem
              className="m-3"
              style={{ position: "relative", top: "8px" }}
            >
              <NavLink
                className={classnames({ active: activeTab === "5" })}
                onClick={() => {
                  toggle("5")
                }}
              >
                <a href="#team-desc">
                  {" "}
                  <img alt="" className="team-portrait" src={staffJohn4} />{" "}
                </a>{" "}
              </NavLink>{" "}
              <div className="team-info text-center mt-3">
                <h5 className="mb-0"> Jonathan Morgan </h5>{" "}
                <p>
                  Director of <br />
                  National Sales{" "}
                </p>{" "}
              </div>{" "}
            </NavItem>{" "}
            {/* <NavItem
                          className="m-3"
                          style={{ position: "relative", top: "8px" }}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === "12" })}
                            onClick={() => {
                              toggle("12")
                            }}
                          >
                            <a href="#team-desc">
                              <img alt="" className="team-portrait" src={staffJohn11} />
                            </a>
                          </NavLink>
                          <div className="team-info text-center mt-3">
                            <h5 className="mb-0">Melissa Darke</h5>
                            <p>
                              Business Development
                              <br /> Manager
                            </p>
                          </div>
                        </NavItem> */}{" "}
          </Nav>{" "}
          {/* <Nav
                    tabs
                    className="d-flex justify-content-center align-items-center"
                  >
                    <NavItem
                      className="m-3"
                      style={{ position: "relative", top: "8px" }}
                    >
                      <NavLink
                        className={classnames({ active: activeTab === "12" })}
                        onClick={() => {
                          toggle("12")
                        }}
                      >
                        <a href="#team-desc">
                          <img alt="" className="team-portrait" src={staffJohn11} />
                        </a>
                      </NavLink>
                      <div className="team-info text-center mt-3">
                        <h5 className="mb-0">Melissa Darke</h5>
                        <p>
                          Business Development
                          <br /> Manager
                        </p>
                      </div>
                    </NavItem>
                  </Nav> */}{" "}
          <Nav
            tabs
            className="d-flex justify-content-center align-items-center"
          >
            {" "}
            {/* <NavItem className="m-3">
                      <NavLink
                        className={classnames({ active: activeTab === "11" })}
                        onClick={() => {
                          toggle("24")
                        }}
                      >
                        <a href="#team-desc">
                          {" "}
                          <img alt="" className="team-portrait" src={staffcarson} />
                        </a>
                      </NavLink>
                      <div className="team-info text-center mt-3">
                        <h5 className="mb-0">Carson Daniel</h5>
                        <p>Account Manager</p>
                      </div>
                    </NavItem> */}{" "}
            {/* <NavItem
                      className="m-3"
                      style={{ position: "relative", top: "8px" }}
                    >
                      <NavLink
                        className={classnames({ active: activeTab === "24" })}
                        onClick={() => {
                          toggle("24")
                        }}
                      >
                        <a href="#team-desc">
                          <img alt="" className="team-portrait" src={staffcarson} />
                        </a>
                      </NavLink>
                      <div className="team-info text-center mt-3">
                        <h5 className="mb-0">Carson Daniel</h5>
                        <p>Account Manager</p>
                      </div>
                    </NavItem> */}{" "}
            <NavItem
              className="m-3"
              style={{ position: "relative", top: "8px" }}
            >
              <NavLink
                className={classnames({ active: activeTab === "8" })}
                onClick={() => {
                  toggle("8")
                }}
              >
                <a href="#team-desc">
                  {" "}
                  <img alt="" className="team-portrait" src={staffJohn7} />{" "}
                </a>{" "}
              </NavLink>{" "}
              <div className="team-info text-center mt-3">
                <h5 className="mb-0"> Chris Snellings </h5>{" "}
                <p>
                  Payroll Manager <br />
                  Technology Recruiter{" "}
                </p>{" "}
              </div>{" "}
            </NavItem>
            <NavItem className="m-3">
              <NavLink
                className={classnames({ active: activeTab === "11" })}
                onClick={() => {
                  toggle("11")
                }}
              >
                <a href="#team-desc">
                  {" "}
                  <img
                    alt=""
                    className="team-portrait"
                    src={staffJohn10}
                  />{" "}
                </a>{" "}
              </NavLink>{" "}
              <div className="team-info text-center mt-3">
                <h5 className="mb-0"> Erika Eiland </h5>{" "}
                <p> Technology Recruiter </p>{" "}
              </div>{" "}
            </NavItem>
            {/* <NavItem className="m-3">
                  <NavLink
                    className={classnames({ active: activeTab === "10" })}
                    onClick={() => {
                      toggle("10")
                    }}
                  >
                    <a href="#team-desc">
                      {" "}
                      <img alt="" className="team-portrait" src={staffJohn9} />
                    </a>
                  </NavLink>
                  <div className="team-info text-center mt-3">
                    <h5 className="mb-0">Jessica Oliver</h5>
                    <p>Technology Recruiter</p>
                  </div>
                </NavItem> */}
            {/* <NavItem className="m-3">
                  <NavLink
                    className={classnames({ active: activeTab === "9" })}
                    onClick={() => {
                      toggle("9")
                    }}
                  >
                    <a href="#team-desc">
                      {" "}
                      <img alt="" className="team-portrait" src={staffJohn16} />
                    </a>
                  </NavLink>

                  <div className="team-info text-center mt-3">
                    <h5 className="mb-0">Franco Burgos</h5>
                    <p>Technology Recruiter</p>
                  </div>
                </NavItem> */}
            {/* <NavItem className="m-3">
                  <NavLink
                    className={classnames({ active: activeTab === "17" })}
                    onClick={() => {
                      toggle("17")
                    }}
                  >
                    <a href="#team-desc">
                      {" "}
                      <img alt="" className="team-portrait" src={staffJohn17} />
                    </a>
                  </NavLink>

                  <div className="team-info text-center mt-3">
                    <h5 className="mb-0"> Lauren Di Blasi</h5>
                    <p>Technology Recruiter</p>
                  </div>
                </NavItem> */}
            {/* <NavItem className="m-3">
                  <NavLink
                    className={classnames({ active: activeTab === "13" })}
                    onClick={() => {
                      toggle("13")
                    }}
                  >
                    <a href="#team-desc">
                      {" "}
                      <img alt="" className="team-portrait" src={staffJohn12} />
                    </a>
                  </NavLink>

                  <div className="team-info text-center mt-3">
                    <h5 className="mb-0">Karrie McCorkle</h5>
                    <p>Technology Recruiter</p>
                  </div>
                </NavItem> */}
            <NavItem className="m-3">
              <div>
                {" "}
                <NavLink
                  className={classnames({ active: activeTab === "23" })}
                  onClick={() => {
                    toggle("23")
                  }}
                >
                  <a href="#team-desc">
                    {" "}
                    <img alt="" className="team-portrait" src={staffrob} />{" "}
                  </a>{" "}
                </NavLink>{" "}
                <div className="team-info text-center mt-3">
                  <h5 className="mb-0"> Rob Case </h5>{" "}
                  <p> Technology Recruiter </p>{" "}
                </div>{" "}
              </div>{" "}
            </NavItem>{" "}
            <NavItem className="m-3">
              <div>
                {" "}
                <NavLink
                  className={classnames({ active: activeTab === "25" })}
                  onClick={() => {
                    toggle("25")
                  }}
                >
                  <a href="#team-desc">
                    {" "}
                    <img
                      alt=""
                      className="team-portrait"
                      src={staffcaitlin}
                    />{" "}
                  </a>{" "}
                </NavLink>{" "}
                <div className="team-info text-center mt-3">
                  <h5 className="mb-0"> Caitlin Blakeley </h5>{" "}
                  <p> Technology Recruiter </p>{" "}
                </div>{" "}
              </div>{" "}
            </NavItem>{" "}
            <NavItem className="m-3">
              <NavLink
                className={classnames({ active: activeTab === "14" })}
                onClick={() => {
                  toggle("14")
                }}
              >
                <a href="#team-desc">
                  {" "}
                  <img
                    alt=""
                    className="team-portrait"
                    src={staffJohn13}
                  />{" "}
                </a>{" "}
              </NavLink>
              <div className="team-info text-center mt-3">
                <h5 className="mb-0"> Kylie Arnold </h5>{" "}
                <p> Talent Acquisition Specialist </p>{" "}
              </div>{" "}
            </NavItem>
            {/* <NavItem className="m-3">
              <NavLink
                className={classnames({ active: activeTab === "15" })}
                onClick={() => {
                  toggle("15")
                }}
              >
                <a href="#team-desc">
                  {" "}
                  <img
                    alt=""
                    className="team-portrait"
                    src={staffJohn14}
                  />{" "}
                </a>{" "}
              </NavLink>
              <div className="team-info text-center mt-3">
                <h5 className="mb-0"> Cynthia Jurado </h5>{" "}
                <p> Recruiting Support Specialist </p>{" "}
              </div>{" "}
            </NavItem> */}
            <NavItem className="m-3">
              <NavLink
                className={classnames({ active: activeTab === "19" })}
                onClick={() => {
                  toggle("19")
                }}
              >
                <a href="#team-desc">
                  {" "}
                  <img alt="" className="team-portrait" src={staffjason} />{" "}
                </a>{" "}
              </NavLink>
              <div className="team-info text-center mt-3">
                <h5 className="mb-0"> Jason Muilenburg </h5>{" "}
                <p> Talent Acquisition Specialist </p>{" "}
              </div>{" "}
            </NavItem>{" "}
            <NavItem className="m-3">
              <NavLink
                className={classnames({ active: activeTab === "20" })}
                onClick={() => {
                  toggle("20")
                }}
              >
                <a href="#team-desc">
                  {" "}
                  <img alt="" className="team-portrait" src={staffjay} />{" "}
                </a>{" "}
              </NavLink>{" "}
              <div className="team-info text-center mt-3">
                <h5 className="mb-0"> Jay Snellings </h5>{" "}
                <p style={{ fontSize: "69%" }}>Data Analyst </p>{" "}
              </div>{" "}
            </NavItem>
            {/* <NavItem className="m-3">
                  <NavLink
                    className={classnames({ active: activeTab === "20" })}
                    onClick={() => {
                      toggle("20")
                    }}
                  >
                    <a href="#team-desc">
                      {" "}
                      <img alt="" className="team-portrait" src={saraimg} />
                    </a>
                  </NavLink>
                  <div className="team-info text-center mt-3">
                    <h5 className="mb-0">Sarah Bedor</h5>
                    <p style={{ fontSize: "69%" }}>
                      Healthcare Scheduling Coordinator
                    </p>
                  </div>
                </NavItem> */}
            <NavItem className="m-3">
              <NavLink
                className={classnames({ active: activeTab === "7" })}
                onClick={() => {
                  toggle("7")
                }}
              >
                <a href="#team-desc">
                  {" "}
                  <img alt="" className="team-portrait" src={staffJohn6} />{" "}
                </a>{" "}
              </NavLink>{" "}
              <div className="team-info text-center mt-3">
                <h5 className="mb-0"> Chandra Richards </h5>{" "}
                <p> Administrative Assistant </p>{" "}
              </div>{" "}
            </NavItem>{" "}
            {/* <NavItem className="m-3">
                  <div>
                    {" "}
                    <NavLink
                      className={classnames({ active: activeTab === "22" })}
                      onClick={() => {
                        toggle("22")
                      }}
                    >
                      <a href="#team-desc">
                        {" "}
                        <img alt="" className="team-portrait" src={staffjonathan} />
                      </a>
                    </NavLink>
                    <div className="team-info text-center mt-3">
                      <h5 className="mb-0">Jonathan Lopez</h5>
                      <p>Staffing Coordinator Intern</p>
                    </div>
                  </div>
                </NavItem> */}{" "}
            {/* <NavItem className="m-3">
                  <div>
                    {" "}
                    <NavLink
                      className={classnames({ active: activeTab === "22" })}
                      onClick={() => {
                        toggle("22")
                      }}
                    >
                      <a href="#team-desc">
                        {" "}
                        <img alt="" className="team-portrait" src={stafftaryn} />
                      </a>
                    </NavLink>
                    <div className="team-info text-center mt-3">
                      <h5 className="mb-0">Taryn Dragseth</h5>
                      <p>Social Media Coordinator</p>
                    </div>
                  </div>
                </NavItem> */}
            {/* <NavItem className="m-3">
                  <div>
                    {" "}
                    <NavLink
                      className={classnames({ active: activeTab === "6" })}
                      onClick={() => {
                        toggle("6")
                      }}
                    >
                      <a href="#team-desc">
                        {" "}
                        <img alt="" className="team-portrait" src={staffJohn5} />
                      </a>
                    </NavLink>
                    <div className="team-info text-center mt-3">
                      <h5 className="mb-0">Samantha Snellings</h5>
                      <p>Social Media Manager</p>
                    </div>
                  </div>
                </NavItem> */}
            {/* <NavItem className="m-3">
                  <NavLink
                    className={classnames({ active: activeTab === "16" })}
                    onClick={() => {
                      toggle("16")
                    }}
                  >
                    <a href="#team-desc">
                      {" "}
                      <img alt="" className="team-portrait" src={staffJohn15} />
                    </a>
                  </NavLink>

                  <div className="team-info text-center mt-3">
                    <h5 className="mb-0">Jamie Wage</h5>
                    <p>Staffing Coordinator Intern</p>
                  </div>
                </NavItem> */}{" "}
          </Nav>{" "}
        </div>{" "}
      </div>
      <div
        className="container-fluid team-desc"
        id="team-desc"
        style={{ paddingTop: "200px", marginTop: "-200px" }}
      >
        <div className="container" style={{ padding: "50px 0" }}>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <Row className="flex-column-reverse flex-lg-row">
                <Col
                  className="d-flex justify-content-center align-items-center"
                  lg="7"
                >
                  <div id="team-desc">
                    <Row>
                      <Col>
                        <h3 className="mb-0"> John Snellings </h3>{" "}
                        <h4 className="mb-4" style={{ color: "#00C1C8" }}>
                          <small> CEO/Co-Founder </small>{" "}
                        </h4>{" "}
                      </Col>{" "}
                      <Col lg="6">
                        <a href="mailto:john.snellings@mypeersource.com">
                          <img alt="" className="mx-2" src={Email} />{" "}
                        </a>{" "}
                        <a
                          href="https://www.linkedin.com/in/johnsnellings/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img alt="" className="mx-2" src={Linkedin} />{" "}
                        </a>{" "}
                      </Col>{" "}
                    </Row>
                    <p>
                      John Snellings is a serial entrepreneur who has founded 12
                      companies in a variety of industries throughout his
                      career. Having started his first company at age 19, John
                      has 20+ years of experience in the recruiting industry.
                      Along with his business partner John Smith, he founded
                      PeerSource back in 2011. Born in KC & raised in rural
                      Kansas, he still cheers for the Chiefs, Royals, and
                      somehow the Dallas Cowboys.When the weather gets "bad” and
                      the snow flies from November–January, you’ll often find
                      that John is pheasant hunting along some country road in
                      the plains of Kansas or South Dakota.{" "}
                    </p>{" "}
                  </div>{" "}
                </Col>{" "}
                <Col className="text-center" lg="5">
                  <img
                    alt=""
                    style={{ width: "300px" }}
                    className=""
                    src={staffJohn}
                  />{" "}
                </Col>{" "}
              </Row>{" "}
            </TabPane>{" "}
            <TabPane tabId="2">
              <Row className="flex-column-reverse flex-lg-row">
                <Col
                  className="d-flex justify-content-center align-items-center"
                  lg="7"
                >
                  <div id="team-desc">
                    <Row>
                      <Col>
                        <h3 className="mb-0"> John Smith </h3>{" "}
                        <h4 className="mb-4" style={{ color: "#00C1C8" }}>
                          <small> Co-Founder </small>{" "}
                        </h4>{" "}
                      </Col>{" "}
                      <Col lg="6">
                        <a
                          href="mailto:JSmith@jdscpagroup.com"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img alt="" className="mx-2" src={Email} />{" "}
                        </a>{" "}
                        <a
                          href="https://www.linkedin.com/in/john-smith-b646064/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {" "}
                          <img alt="" className="mx-2" src={Linkedin} />{" "}
                        </a>{" "}
                      </Col>{" "}
                    </Row>
                    <p>
                      John Smith is a serial entrepreneur with 30+ years of
                      experience starting, leading and driving businesses.Since
                      1983, John has grown his business holdings from a single
                      Denver based Accounting & consulting firm to a group of
                      companies designed to solve a variety of client
                      problems.Services offered include PEO/Co-employment, HR
                      and Payroll Services, Tax, Audit, Investment/401 k
                      management, Technology Recruiting, Office/Clerical & Light
                      Industrial recruiting, Managed Services, Real Estate,
                      Leasing, Professional Insurance Services, etc.Combined
                      company revenues are in the hundreds of millions of
                      dollars and growing quickly. A native of Colorado, John
                      loves to get out on golf courses at home and around the
                      country to chase that little white ball down the fairway!
                    </p>{" "}
                  </div>{" "}
                </Col>{" "}
                <Col className="text-center" lg="5">
                  <img
                    alt=""
                    style={{ width: "300px" }}
                    className=""
                    src={staffJohn1}
                  />{" "}
                </Col>{" "}
              </Row>{" "}
            </TabPane>{" "}
            <TabPane tabId="3">
              <Row className="flex-column-reverse flex-lg-row">
                <Col
                  className="d-flex justify-content-center align-items-center"
                  lg="7"
                >
                  <div>
                    <Row>
                      <Col lg="6">
                        <h3 className="mb-0"> Cory Blackwood </h3>{" "}
                        <h4 className="mb-4" style={{ color: "#00C1C8" }}>
                          <small> CFO </small>{" "}
                        </h4>{" "}
                      </Col>{" "}
                      <Col lg="6">
                        <a
                          href="mailto:cory.blackwood@crescentch.com"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img alt="" className="mx-2" src={Email} />{" "}
                        </a>{" "}
                        <a
                          href="https://www.linkedin.com/in/cory-blackwood-3467948b/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {" "}
                          <img alt="" className="mx-2" src={Linkedin} />{" "}
                        </a>{" "}
                      </Col>{" "}
                    </Row>
                    <p>
                      Cory Blackwood brings over 20 years of accounting and
                      financial leadership experience to the PeerSource team. He
                      has held CFO and Controller positions in several small to
                      mid-size business in the healthcare, retail services,
                      franchising, light manufacturing and wholesale
                      distribution sectors.With an early career in Operations
                      and Supply Chain Management, Cory takes pride in being
                      able to help companies and people build synergies between
                      Finance and Business Operations. A Colorado native and
                      avid sportsman, Cory and his family enjoy all things
                      outdoors, from hunting and fishing to gardening and
                      camping including the occasional beach vacation when the
                      schedule permits.{" "}
                    </p>{" "}
                  </div>{" "}
                </Col>{" "}
                <Col className="text-center" lg="5">
                  <img
                    alt=""
                    style={{ width: "300px" }}
                    className=""
                    src={staffJohn2}
                  />{" "}
                </Col>{" "}
              </Row>{" "}
            </TabPane>{" "}
            <TabPane tabId="4">
              <Row className="flex-column-reverse flex-lg-row">
                <Col
                  className="d-flex justify-content-center align-items-center"
                  lg="7"
                >
                  <div>
                    <Row>
                      <Col lg="5">
                        <h3 className="mb-0"> Tervor Richards </h3>{" "}
                        <h4 className="mb-4" style={{ color: "#00C1C8" }}>
                          <small> Director of Client Services </small>{" "}
                        </h4>{" "}
                      </Col>{" "}
                      <Col lg="5">
                        <a
                          href="mailto:trevor.richards@mypeersource.com"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img alt="" className="mx-2" src={Email} />{" "}
                        </a>{" "}
                        <a
                          href="https://www.linkedin.com/in/trevorkrichards/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {" "}
                          <img alt="" className="mx-2" src={Linkedin} />{" "}
                        </a>{" "}
                      </Col>{" "}
                    </Row>
                    <p>
                      Trevor Richards currently serves as the Director of Client
                      Services at PeerSource. He not only has his hand in the
                      recruiting operations cookie jar but he also builds and
                      maintains relationships with our clients. His passion is
                      the technology industry, beginning with his Bachelors in
                      Information Systems from UC Denver to joining PeerSource
                      as a junior technology sourcer back in 2013. Other than
                      technology, he loves both playing & watching sports and
                      can often find him at any Colorado-based sporting event
                      cheering on the home team.{" "}
                    </p>{" "}
                  </div>{" "}
                </Col>{" "}
                <Col className="text-center" lg="5">
                  <img
                    alt=""
                    style={{ width: "300px" }}
                    className=""
                    src={trevor}
                  />{" "}
                </Col>{" "}
              </Row>{" "}
            </TabPane>{" "}
            <TabPane tabId="5">
              <Row className="flex-column-reverse flex-lg-row">
                <Col
                  className="d-flex justify-content-center align-items-center"
                  lg="7"
                >
                  <div>
                    <Row>
                      <Col>
                        <h3 className="mb-0"> Jonathan Morgan </h3>{" "}
                        <h4 className="mb-4" style={{ color: "#00C1C8" }}>
                          <small> Director of National Sales </small>{" "}
                        </h4>{" "}
                      </Col>{" "}
                      <Col lg="6">
                        <a
                          href="mailto:jonathan.morgan@mypeersource.com"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img alt="" className="mx-2" src={Email} />{" "}
                        </a>{" "}
                        <a
                          href="https://www.linkedin.com/in/jonathanmorgan1/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {" "}
                          <img alt="" className="mx-2" src={Linkedin} />{" "}
                        </a>{" "}
                      </Col>{" "}
                    </Row>
                    <p>
                      Jonathan is a Director of National Sales with PeerSource.
                      His passion is to meet and build strong, long-term
                      professional relationships with hiring leaders and the
                      candidate community. Jonathan's number one focus as a
                      PeerSource BDM is understanding the specific job
                      requirements of our client hiring leaders we serve and the
                      candidate 's we represent. Getting those details right is
                      the only path to genuinely successful placements for
                      both.When not working, Jonathan enjoys spending quality
                      time with his wife and dogs, cooking, golfing, and waiting
                      for football season. Jonathan and his wife support no -
                      kill animal rescues and feed our children charities.{" "}
                    </p>{" "}
                  </div>{" "}
                </Col>{" "}
                <Col className="text-center" lg="5">
                  <img
                    alt=""
                    style={{ width: "300px" }}
                    className=""
                    src={staffJohn4}
                  />{" "}
                </Col>{" "}
              </Row>{" "}
            </TabPane>{" "}
            {/* <TabPane tabId="6">
                  <Row className="flex-column-reverse flex-lg-row">
                    <Col
                      className="d-flex justify-content-center align-items-center"
                      lg="7"
                    >
                      <div>
                        <Row>
                          <Col>
                            <h3 className="mb-0">Samantha Snellings</h3>
                            <h4 className="mb-4" style={{ color: "#00C1C8" }}>
                              <small>Social Media Manager</small>
                            </h4>
                          </Col>
                          <Col lg="6">
                            <a
                              href="mailto:samanthasnellings1@gmail.com"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img alt="" className="mx-2" src={Email} />
                            </a>
                            <a
                              href="https://www.linkedin.com/in/samisnellings/"
                              target="_blank"
                              rel="noreferrer"
                            >
                              {" "}
                              <img alt="" className="mx-2" src={Linkedin} />
                            </a>
                          </Col>
                        </Row>

                        <p>
                          Samantha Snellings is the Social Media Manager at
                          PeerSource and a student at the University of North
                          Carolina at Chapel Hill. Last summer, she interned for
                          senior White House correspondent, John Gizzi, in
                          Washington. She attended the daily White House press
                          briefings, wrote articles, and only occasionally got in
                          trouble with the Secret Service for stepping on the
                          president’s lawn. She is passionate about her faith,
                          journalism, entrepreneurship, traveling, and politics.
                        </p>
                        <p>
                          Follow Samantha on Instagram and Twitter or connect with
                          her on LinkedIn.
                        </p>
                      </div>
                    </Col>
                    <Col className="text-center" lg="5">
                      <img
                        alt=""
                        style={{ width: "300px" }}
                        className=""
                        src={staffJohn5}
                      />
                    </Col>
                  </Row>
                </TabPane> */}
            <TabPane tabId="7">
              <Row className="flex-column-reverse flex-lg-row">
                <Col
                  className="d-flex justify-content-center align-items-center"
                  lg="7"
                >
                  <div>
                    <Row>
                      <Col>
                        <h3 className="mb-0"> Chandra Richards </h3>{" "}
                        <h4 className="mb-4" style={{ color: "#00C1C8" }}>
                          <small> Administrative Assistant </small>{" "}
                        </h4>{" "}
                      </Col>{" "}
                      <Col lg="6">
                        <a
                          href="mailto:chandra.richards@mypeersource.com"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img alt="" className="mx-2" src={Email} />{" "}
                        </a>{" "}
                        <a
                          href="https://www.linkedin.com/in/chandra-richards-20721a13b/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {" "}
                          <img alt="" className="mx-2" src={Linkedin} />{" "}
                        </a>{" "}
                      </Col>{" "}
                    </Row>
                    <p>
                      Chandra currently supports our CEO and staff from an
                      administrative capacity. She is originally from a small
                      town in Iowa and after moving to Colorado and going to
                      Culinary school, she found herself in the recruiting
                      industry working alongside her husband! She
                      self-identifies as a true crime junkie, loves her 2 cats,
                      and spends her waking hours doing her side business of
                      embroidering.{" "}
                    </p>{" "}
                  </div>{" "}
                </Col>{" "}
                <Col className="text-center" lg="5">
                  <img
                    alt=""
                    style={{ width: "300px" }}
                    className=""
                    src={chandra}
                  />{" "}
                </Col>{" "}
              </Row>{" "}
            </TabPane>
            <TabPane tabId="8">
              <Row className="flex-column-reverse flex-lg-row">
                <Col
                  className="d-flex justify-content-center align-items-center"
                  lg="7"
                >
                  <div>
                    <Row>
                      <Col>
                        <h3 className="mb-0"> Chris Snellings </h3>{" "}
                        <h4 className="mb-4" style={{ color: "#00C1C8" }}>
                          <small> Payroll Manager/Technology Recruiter </small>{" "}
                        </h4>{" "}
                      </Col>{" "}
                      <Col lg="6">
                        <a
                          href="mailto:chris.snellings@mypeersource.com"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img alt="" className="mx-2" src={Email} />{" "}
                        </a>{" "}
                        <a
                          href="https://www.linkedin.com/in/chris-snellings-11912751/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {" "}
                          <img alt="" className="mx-2" src={Linkedin} />{" "}
                        </a>{" "}
                      </Col>{" "}
                    </Row>
                    <p>
                      Chris Snellings has been involved in the recruiting
                      industry for the past 18 years. She is the much better -
                      half of John and has been a participant in his
                      entrepreneurial endeavors.Chris is our Payroll Manager and
                      a full-life cycle recruiter with experience in technical,
                      medical, oil and gas, accounting, and executive
                      recruiting. As well as being a busy mom of four, she is
                      currently finishing up her degree that she started at
                      Kansas State University where she met John. Go Cats! Chris
                      loves building relationships, traveling, and spending time
                      with family and friends.{" "}
                    </p>{" "}
                  </div>{" "}
                </Col>{" "}
                <Col className="text-center" lg="5">
                  <img
                    alt=""
                    style={{ width: "300px" }}
                    className=""
                    src={cris}
                  />{" "}
                </Col>{" "}
              </Row>{" "}
            </TabPane>
            {/* <TabPane tabId="9">
                      <Row className="flex-column-reverse flex-lg-row">
                        <Col
                          className="d-flex justify-content-center align-items-center"
                          lg="7"
                        >
                          <div>
                            <Row>
                              <Col>
                                <h3 className="mb-0">Franco Burgos</h3>
                                <h4 className="mb-4" style={{ color: "#00C1C8" }}>
                                  <small>Technology Recruiter</small>
                                </h4>
                              </Col>
                              <Col lg="6">
                                <a
                                  href="mailto:franco.burgos@mypeersource.com"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <img alt="" className="mx-2" src={Email} />
                                </a>
                                <a
                                  href="https://www.linkedin.com/in/francob/"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {" "}
                                  <img alt="" className="mx-2" src={Linkedin} />
                                </a>
                              </Col>
                            </Row>

                            <p>
                              Franco Burgos is a well-seasoned Technology Recruiter that
                              has joined the PeerSource team to attract the right talent
                              for our clients. His background is in Information
                              Technology and has a Bachelors in Computer Engineering
                              from the University of Central Florida. Recruiting for him
                              is a very satisfying career since it makes an impact on
                              people’s lives, both for the candidates and for our
                              clients. In his spare time, he likes to work out and do a
                              little Latin Dancing.
                            </p>
                          </div>
                        </Col>
                        <Col className="text-center" lg="5">
                          <img
                            alt=""
                            style={{ width: "300px" }}
                            className=""
                            src={staffJohn16}
                          />
                        </Col>
                      </Row>
                    </TabPane> */}
            {/* <TabPane tabId="10">
                  <Row className="flex-column-reverse flex-lg-row">
                    <Col
                      className="d-flex justify-content-center align-items-center"
                      lg="7"
                    >
                      <div>
                        <Row>
                          <Col>
                            <h3 className="mb-0">Jessica Oliver</h3>
                            <h4 className="mb-4" style={{ color: "#00C1C8" }}>
                              <small>Technology Recruiter</small>
                            </h4>
                          </Col>
                          <Col lg="6">
                            <a
                              href="mailto:jessica.oliver@mypeersource.com"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img alt="" className="mx-2" src={Email} />
                            </a>
                            <a
                              href="https://www.linkedin.com/in/oliver00jessica/"
                              target="_blank"
                              rel="noreferrer"
                            >
                              {" "}
                              <img alt="" className="mx-2" src={Linkedin} />
                            </a>
                          </Col>
                        </Row>

                        <p>
                          Jessica has spent her entire professional career in human
                          resources/training and development. Jessica is most
                          passionate about helping people find opportunities that
                          pose a significant improvement over their current
                          situation, whether that be professional development and
                          growth, an increase in compensation, or better work life
                          balance. Jessica holds a PHR and comes from the startup
                          realm. She is part of our Florida team, has two little
                          boys and one girl, and loves Disney.
                        </p>
                      </div>
                    </Col>
                    <Col className="text-center" lg="5">
                      <img
                        alt=""
                        style={{ width: "300px" }}
                        className=""
                        src={staffJohn9}
                      />
                    </Col>
                  </Row>
                </TabPane> */}
            <TabPane tabId="11">
              <Row className="flex-column-reverse flex-lg-row">
                <Col
                  className="d-flex justify-content-center align-items-center"
                  lg="7"
                >
                  <div>
                    <Row>
                      <Col>
                        <h3 className="mb-0"> Erika Eiland </h3>{" "}
                        <h4 className="mb-4" style={{ color: "#00C1C8" }}>
                          <small> Technology Recruiter </small>{" "}
                        </h4>{" "}
                      </Col>{" "}
                      <Col lg="6">
                        <a
                          href="mailto:Erika.eiland@mypeersource.com"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img alt="" className="mx-2" src={Email} />{" "}
                        </a>{" "}
                        <a
                          href="https://www.linkedin.com/in/erikaanderson372/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {" "}
                          <img alt="" className="mx-2" src={Linkedin} />{" "}
                        </a>{" "}
                      </Col>{" "}
                    </Row>
                    <p>
                      Erika Eiland has 10+ years in recruiting with the last 8
                      years focusing in the technology industry. She is
                      passionate about changing people’s lives and impacting
                      businesses by providing the right candidates. She is also
                      very competitive and enjoys winning. She is a part of our
                      Florida team. She has a degree in Legal Studies from the
                      University of Central Florida, but she stumbled upon the
                      recruiting industry after graduation and never looked
                      back.Outside of the office, you can find Erika on the
                      beach, spending time with her family, or enjoying a day at
                      Disney.{" "}
                    </p>{" "}
                  </div>{" "}
                </Col>{" "}
                <Col className="text-center" lg="5">
                  <img
                    alt=""
                    style={{ width: "300px" }}
                    className=""
                    src={staffJohn10}
                  />{" "}
                </Col>{" "}
              </Row>{" "}
            </TabPane>{" "}
            {/* <TabPane tabId="12">
                  <Row className="flex-column-reverse flex-lg-row">
                    <Col
                      className="d-flex justify-content-center align-items-center"
                      lg="7"
                    >
                      <div>
                        <Row>
                          <Col lg="6">
                            <h3 className="mb-0">Melissa Darke</h3>
                            <h4 className="mb-4" style={{ color: "#00C1C8" }}>
                              <small>Business Development Manager</small>
                            </h4>
                          </Col>
                          <Col lg="6">
                            <a
                              href="mailto:melissa.darke@mypeersource.com"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img alt="" className="mx-2" src={Email} />
                            </a>
                            <a
                              href="https://www.linkedin.com/in/melissa-darke-2b6600/"
                              target="_blank"
                              rel="noreferrer"
                            >
                              {" "}
                              <img alt="" className="mx-2" src={Linkedin} />
                            </a>
                          </Col>
                        </Row>

                        <p>
                          Melissa Darke has spent 23 years in the recruiting
                          industry, with the past 4 years as a Business Development
                          Manager. Melissa is passionate about connecting with
                          people, helping others achieve their goals, loves client
                          relationships and finding the right candidate for her
                          clients. Melissa is originally from Florida and moved to
                          Colorado after college. Now, she spends a lot of time
                          between Florida and Colorado as both of her kids compete
                          nationally in all three events waterskiing. In her free
                          time, she loves being in the mountains skiing, hiking, and
                          biking OR on the beach paddle boarding, kayaking, and
                          watching beautiful sunrises and sunsets. In the fall, you
                          will find her watching college football on most Saturdays
                          as she is a true blue Florida Gator- she loves SEC
                          football. She enjoys spending time with her family and
                          friends as well as traveling and exploring new
                          destinations.
                        </p>
                      </div>
                    </Col>
                    <Col className="text-center" lg="5">
                      <img
                        alt=""
                        style={{ width: "300px" }}
                        className=""
                        src={mellisa}
                      />
                    </Col>
                  </Row>
                </TabPane> */}{" "}
            {/* <TabPane tabId="13">
                  <Row className="flex-column-reverse flex-lg-row">
                    <Col
                      className="d-flex justify-content-center align-items-center"
                      lg="7"
                    >
                      <div>
                        <Row>
                          <Col lg="6">
                            <h3 className="mb-0">Karrie McCorkle</h3>
                            <h4 className="mb-4" style={{ color: "#00C1C8" }}>
                              <small>Technology Recruiter</small>
                            </h4>
                          </Col>
                          <Col lg="6">
                            <a
                              href="mailto:karrie.mccorkle@mypeersource.com"
                              rel="noreferrer"
                            >
                              <img alt="" className="mx-2" src={Email} />
                            </a>
                            <a
                              href="https://www.linkedin.com/in/karriem/"
                              target="_blank"
                              rel="noreferrer"
                            >
                              {" "}
                              <img alt="" className="mx-2" src={Linkedin} />
                            </a>
                          </Col>
                        </Row>

                        <p>
                          Karrie McCorkle is currently a Technology Recruiter at
                          PeerSource. She recently graduated from Regis University
                          with a B.S. in Business Administration with an emphasis in
                          Marketing and Management. Additionally, she obtained her
                          M.S. in Organizational Leadership. She is a Colorado
                          native and enjoys the diverse weather that our great state
                          Colorado has to offer. Her favorite place to explore in
                          Colorado is Glenwood Springs. Karrie enjoys spending time
                          with family and friends, traveling, and spoiling her two
                          huskies.
                        </p>
                      </div>
                    </Col>
                    <Col className="text-center" lg="5">
                      <img
                        alt=""
                        style={{ width: "300px" }}
                        className=""
                        src={karrie}
                      />
                    </Col>
                  </Row>
                </TabPane> */}
            <TabPane tabId="14">
              <Row className="flex-column-reverse flex-lg-row">
                <Col
                  className="d-flex justify-content-center align-items-center"
                  lg="7"
                >
                  <div>
                    <Row>
                      <Col lg="6">
                        <h3 className="mb-0"> Kylie Arnold </h3>{" "}
                        <h4 className="mb-4" style={{ color: "#00C1C8" }}>
                          <small> Talent Acquisition Specialist </small>{" "}
                        </h4>{" "}
                      </Col>{" "}
                      <Col lg="6">
                        <a
                          href="mailto:kylie. Arnold@mypeersource.com"
                          rel="noreferrer"
                        >
                          <img alt="" className="mx-2" src={Email} />{" "}
                        </a>{" "}
                        <a
                          href="https://www.linkedin.com/in/kylie-arnold-a64821211/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {" "}
                          <img alt="" className="mx-2" src={Linkedin} />{" "}
                        </a>{" "}
                      </Col>{" "}
                    </Row>
                    <p>
                      Kylie Arnold is the Talent Acquisition Specialist at PSN
                      Staffing, the shared services arm that supports PeerSource
                      and Nextaff Healthcare. She studied Nursing at Colorado
                      Christian University and has years of experience in
                      pharmacy and geriatrics. She joined the team in May 2021
                      because of her growing interest in business and
                      technology. She loves spending time with her kids, going
                      to church and exploring the hidden wonders of Colorado.{" "}
                    </p>{" "}
                  </div>{" "}
                </Col>{" "}
                <Col className="text-center" lg="5">
                  <img
                    alt=""
                    style={{ width: "300px" }}
                    className=""
                    src={kylie}
                  />{" "}
                </Col>{" "}
              </Row>{" "}
            </TabPane>
            {/* <TabPane tabId="15">
              <Row className="flex-column-reverse flex-lg-row">
                <Col
                  className="d-flex justify-content-center align-items-center"
                  lg="7"
                >
                  <div>
                    <Row>
                      <Col lg="6">
                        <h3 className="mb-0"> Cynthia Jurado </h3>{" "}
                        <h4 className="mb-4" style={{ color: "#00C1C8" }}>
                          <small> Recruiting Support Specialist </small>{" "}
                        </h4>{" "}
                      </Col>{" "}
                      <Col lg="6">
                        <a href="mailto:cjurado@nextaff.com" rel="noreferrer">
                          <img alt="" className="mx-2" src={Email} />{" "}
                        </a>{" "}
                        <a
                          href="https://www.linkedin.com/in/cynthia-jurado-29398aa5/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {" "}
                          <img alt="" className="mx-2" src={Linkedin} />{" "}
                        </a>{" "}
                      </Col>{" "}
                    </Row>
                    <p>
                      Cynthia is a Recruiting Support Specialist at PSN
                      Staffing, the shared services arm that supports both
                      PeerSource and Nextaff Healthcare. From an early age
                      Cynthia jumped into recruiting and started paving her way
                      as a Light Industrial Recruiter and Staffing Manager in
                      the Phoenix area. 12 years later she supports new offices
                      launching into Healthcare staffing throughout the US. Her
                      favorite pastime is off-roading and taking road trips.{" "}
                    </p>{" "}
                  </div>{" "}
                </Col>{" "}
                <Col className="text-center" lg="5">
                  <img
                    alt=""
                    style={{ width: "300px" }}
                    className=""
                    src={cyntia}
                  />{" "}
                </Col>{" "}
              </Row>{" "}
            </TabPane> */}
            <TabPane tabId="16">
              <Row className="flex-column-reverse flex-lg-row">
                <Col
                  className="d-flex justify-content-center align-items-center"
                  lg="7"
                >
                  <div>
                    <Row>
                      <Col lg="6">
                        <h3 className="mb-0"> Jamie Wage </h3>{" "}
                        <h4 className="mb-4" style={{ color: "#00C1C8" }}>
                          <small> Staffing Coordinator Intern </small>{" "}
                        </h4>{" "}
                      </Col>{" "}
                      <Col lg="6">
                        <a
                          href="mailto:jwage@nextaff.com"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img alt="" className="mx-2" src={Email} />{" "}
                        </a>{" "}
                        <a
                          href="https://www.linkedin.com/in/jamiewage2000/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {" "}
                          <img alt="" className="mx-2" src={Linkedin} />{" "}
                        </a>{" "}
                      </Col>{" "}
                    </Row>{" "}
                    <p>
                      Jamie Wage is our Staffing Coordinating Intern and a
                      student at the University of Wisconsin-Madison studying
                      psychology(Go Badgers!). Jamie enjoys camping, taking her
                      dog Layla to the park, and trying different breweries and
                      wineries in different cities around the US. She is hoping
                      to learn quickly and adapt to take advantage of her short
                      time at PeerSource this summer.{" "}
                    </p>{" "}
                  </div>{" "}
                </Col>{" "}
                <Col className="text-center" lg="5">
                  <img
                    alt=""
                    style={{ width: "300px" }}
                    className=""
                    src={jamie}
                  />{" "}
                </Col>{" "}
              </Row>{" "}
            </TabPane>{" "}
            {/* <TabPane tabId="17">
                      <Row className="flex-column-reverse flex-lg-row">
                        <Col
                          className="d-flex justify-content-center align-items-center"
                          lg="7"
                        >
                          <div>
                            <Row>
                              <Col>
                                <h3 className="mb-0">Lauren Di Blasi</h3>
                                <h4 className="mb-4" style={{ color: "#00C1C8" }}>
                                  <small>Technology Recruiter</small>
                                </h4>
                              </Col>
                              <Col lg="6">
                                <a
                                  href="mailto:lauren.diblasi@mypeersource.com"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <img alt="" className="mx-2" src={Email} />
                                </a>
                                <a
                                  href="https://www.linkedin.com/in/lauren-di-blasi-50198062"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {" "}
                                  <img alt="" className="mx-2" src={Linkedin} />
                                </a>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                        <Col className="text-center" lg="5">
                          <img
                            alt=""
                            style={{ width: "300px" }}
                            className=""
                            src={staffJohn18}
                          />
                        </Col>
                      </Row>
                    </TabPane> */}{" "}
            <TabPane tabId="19">
              <Row className="flex-column-reverse flex-lg-row">
                <Col
                  className="d-flex justify-content-center align-items-center"
                  lg="7"
                >
                  <div>
                    <Row>
                      <Col>
                        <h3 className="mb-0"> Jason Muilenburg </h3>{" "}
                        <h4 className="mb-4" style={{ color: "#00C1C8" }}>
                          <small> Talent Acquisition Specialist </small>{" "}
                        </h4>{" "}
                      </Col>{" "}
                      <Col lg="6">
                        <a
                          href="mailto:jmuilenburg@nextaff.com"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img alt="" className="mx-2" src={Email} />{" "}
                        </a>{" "}
                        <a
                          href="https://www.linkedin.com/in/jason-muilenburg-89322b21a"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {" "}
                          <img alt="" className="mx-2" src={Linkedin} />{" "}
                        </a>{" "}
                      </Col>{" "}
                    </Row>{" "}
                    <p>
                      Jason is a Talent Acquisition Specialist at PSN Staffing,
                      the shared services arm that supports PeerSource and
                      Nextaff Healthcare. He is currently studying Business
                      Management and plans to open a business of his own
                      someday. He joined the team in 2021 because of his drive
                      and passion to get into the recruiting industry. He loves
                      watching sports(hockey is his favorite), and also loves
                      spending time with and around cars.{" "}
                    </p>{" "}
                  </div>{" "}
                </Col>{" "}
                <Col className="text-center" lg="5">
                  <img
                    alt=""
                    style={{ width: "300px" }}
                    className=""
                    src={staffjason}
                  />{" "}
                </Col>{" "}
              </Row>{" "}
            </TabPane>{" "}
            <TabPane tabId="20">
              <Row className="flex-column-reverse flex-lg-row">
                <Col
                  className="d-flex justify-content-center align-items-center"
                  lg="7"
                >
                  <div>
                    <Row>
                      <Col>
                        <h3 className="mb-0"> Jay Snellings </h3>{" "}
                        <h4 className="mb-4" style={{ color: "#00C1C8" }}>
                          <small> Data Analyst </small>{" "}
                        </h4>{" "}
                      </Col>{" "}
                      <Col lg="6">
                        <a
                          href="mailto:Jay.Snellings@mypeersource.com"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img alt="" className="mx-2" src={Email} />{" "}
                        </a>{" "}
                        <a
                          href="https://www.linkedin.com/in/jay-snellings-186998194/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {" "}
                          <img alt="" className="mx-2" src={Linkedin} />{" "}
                        </a>{" "}
                      </Col>{" "}
                    </Row>{" "}
                    <p>
                      Jay supports our recruiting team by managing our applicant
                      tracking system as well as automating repetitive tasks and
                      systems related to incoming candidates. He is a Colorado
                      native and grew up hunting, skiing, and camping. He enjoys
                      spending time with his friends and is graduating with his
                      Computer Science degree from Colorado State University.
                    </p>{" "}
                  </div>{" "}
                </Col>{" "}
                <Col className="text-center" lg="5">
                  <img
                    alt=""
                    style={{ width: "300px" }}
                    className=""
                    src={staffjay}
                  />{" "}
                </Col>{" "}
              </Row>{" "}
            </TabPane>{" "}
            {/* <TabPane tabId="20">
                      <Row className="flex-column-reverse flex-lg-row">
                        <Col
                          className="d-flex justify-content-center align-items-center"
                          lg="7"
                        >
                          <div>
                            <Row>
                              <Col>
                                <h3 className="mb-0">Sarah Bedor</h3>
                                <h4 className="mb-4" style={{ color: "#00C1C8" }}>
                                  <small>Healthcare Scheduling Coordinator</small>
                                </h4>
                              </Col>
                              <Col lg="6">
                                <a
                                  href="mailto:sarah.bedor@nextaff.com"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <img alt="" className="mx-2" src={Email} />
                                </a>
                                <a
                                  href="https://www.linkedin.com/in/sarahbedor"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {" "}
                                  <img alt="" className="mx-2" src={Linkedin} />
                                </a>
                              </Col>
                            </Row>
                            <p>
                              Sarah is a Healthcare Staffing Coordinator at PSN
                              Staffing. She was born and raised in Phoenix, Arizona and
                              has her Bachelor’s from The University of Arizona Honors
                              College in Neuroscience and Cognitive Science with minors
                              in Spanish and Biochemistry. She also became EMT certified
                              working as site lead of a medical team in Tucson, Arizona.
                              In her free time she enjoys hiking and cooking healthy
                              recipes. She now utilizes her extensive healthcare and
                              business background to help grow our Nextaff Healthcare
                              offices.
                            </p>
                          </div>
                        </Col>
                        <Col className="text-center" lg="5">
                          <img
                            alt=""
                            style={{ width: "300px" }}
                            className=""
                            src={saraimg}
                          />
                        </Col>
                      </Row>
                    </TabPane> */}{" "}
            {/* <TabPane tabId="22">
                      <Row className="flex-column-reverse flex-lg-row">
                        <Col
                          className="d-flex justify-content-center align-items-center"
                          lg="7"
                        >
                          <div>
                            <Row>
                              <Col>
                                <h3 className="mb-0">Jonathan Lopez</h3>
                                <h4 className="mb-4" style={{ color: "#00C1C8" }}>
                                  <small>Staffing Coordinator Intern</small>
                                </h4>
                              </Col>
                              <Col lg="6">
                                <a
                                  href="mailto:Jonathan.Lopez@nextaff.com"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <img alt="" className="mx-2" src={Email} />
                                </a>
                                <a
                                  href="https://www.linkedin.com/in/jonathan-lopez-3a9235214"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {" "}
                                  <img alt="" className="mx-2" src={Linkedin} />
                                </a>
                              </Col>
                            </Row>

                            <p>
                              Jonathan currently serves as a Staffing Coordinator Intern
                              at PSN Staffing focused on Nextaff Healthcare. He recently
                              graduated from the University of Colorado in Boulder with
                              a degree in Psychology as well as a minor in Business
                              Management and Leadership. He is excited to get more
                              invested in the work industry and to make new connections
                              throughout his time. Even though he was born and raised in
                              Colorado, he is fluent in Spanish and loves visiting his
                              family in Mexico once a year. In his free time, he loves
                              hanging out with his friends and watching movies!
                            </p>
                          </div>
                        </Col>
                        <Col className="text-center" lg="5">
                          <img
                            alt=""
                            style={{ width: "300px" }}
                            className=""
                            src={staffjonathan}
                          />
                        </Col>
                      </Row>
                    </TabPane> */}{" "}
            {/* <TabPane tabId="22">
                      <Row className="flex-column-reverse flex-lg-row">
                        <Col
                          className="d-flex justify-content-center align-items-center"
                          lg="7"
                        >
                          <div>
                            <Row>
                              <Col>
                                <h3 className="mb-0">Taryn Dragseth</h3>
                                <h4 className="mb-4" style={{ color: "#00C1C8" }}>
                                  <small>Social Media Coordinator</small>
                                </h4>
                              </Col>
                              <Col lg="6">
                                <a
                                  href="mailto:tdragseth@nextaff.com"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <img alt="" className="mx-2" src={Email} />
                                </a>
                                <a
                                  href="https://www.linkedin.com/in/taryn-dragseth"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {" "}
                                  <img alt="" className="mx-2" src={Linkedin} />
                                </a>
                              </Col>
                            </Row>

                            <p>
                              Taryn is a Social Media Coordinator at PSN Staffing, and a
                              senior at the University of Arizona studying Psychology
                              with a minor in Family Studies and Human Development. She
                              loves working with people and has always been passionate
                              about helping others. In her free time she enjoys spending
                              time with her family, hiking, and watching all the
                              Colorado sports teams.
                            </p>
                          </div>
                        </Col>
                        <Col className="text-center" lg="5">
                          <img
                            alt=""
                            style={{ width: "300px" }}
                            className=""
                            src={stafftaryn}
                          />
                        </Col>
                      </Row>
                    </TabPane> */}{" "}
            <TabPane tabId="23">
              <Row className="flex-column-reverse flex-lg-row">
                <Col
                  className="d-flex justify-content-center align-items-center"
                  lg="7"
                >
                  <div>
                    <Row>
                      <Col>
                        <h3 className="mb-0"> Rob Case </h3>{" "}
                        <h4 className="mb-4" style={{ color: "#00C1C8" }}>
                          <small> Technology Recruiter </small>{" "}
                        </h4>{" "}
                      </Col>{" "}
                      <Col lg="6">
                        <a
                          href="mailto:rob.case@mypeersource.com"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img alt="" className="mx-2" src={Email} />{" "}
                        </a>{" "}
                        <a
                          href="https://www.linkedin.com/in/robert-case-26202614a/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {" "}
                          <img alt="" className="mx-2" src={Linkedin} />{" "}
                        </a>{" "}
                      </Col>{" "}
                    </Row>
                    <p>
                      Rob is a Technology Recruiter at PeerSource. He recently
                      graduated from UCCS and is looking to dive into the
                      professional staffing and recruiting world with
                      PeerSource. In his free time, he enjoys hunting, fishing,
                      camping, and really anything outdoors. He’s a big fan of
                      football and baseball with the Colorado Rockies being his
                      favorite team.If none of those options are available, he
                      is also in the process of working on his car as racing is
                      a passion of his.{" "}
                    </p>{" "}
                  </div>{" "}
                </Col>{" "}
                <Col className="text-center" lg="5">
                  <img
                    alt=""
                    style={{ width: "300px" }}
                    className=""
                    src={staffrob}
                  />{" "}
                </Col>{" "}
              </Row>{" "}
            </TabPane>{" "}
            {/* <TabPane tabId="24">
                      <Row className="flex-column-reverse flex-lg-row">
                        <Col
                          className="d-flex justify-content-center align-items-center"
                          lg="7"
                        >
                          <div>
                            <Row>
                              <Col>
                                <h3 className="mb-0">Carson Daniel</h3>
                                <h4 className="mb-4" style={{ color: "#00C1C8" }}>
                                  <small>Account Manager</small>
                                </h4>
                              </Col>
                              <Col lg="6">
                                <a
                                  href="mailto:carson.daniel@mypeersource.com"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <img alt="" className="mx-2" src={Email} />
                                </a>
                                <a
                                  href=" https://www.linkedin.com/in/carson-daniel"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {" "}
                                  <img alt="" className="mx-2" src={Linkedin} />
                                </a>
                              </Col>
                            </Row>

                            <p>
                              Carson is an Account Manager at PeerSource, who is looking
                              to further his knowledge in the technology and staffing
                              industries. His passion is meeting new people and building
                              friendships that are built to last, through positivity,
                              humor, and trust. Carson was born and raised just outside
                              of Kansas City and graduated from Kansas State University
                              with a degree in Business Administration. When not
                              working, he loves watching sports, including his Chiefs,
                              Royals, and K-State Wildcats. Carson also loves to play
                              golf, explore the mountains, and spend quality time with
                              his loving family.
                            </p>
                          </div>
                        </Col>
                        <Col className="text-center" lg="5">
                          <img
                            alt=""
                            style={{ width: "300px" }}
                            className=""
                            src={staffcarson}
                          />
                        </Col>
                      </Row>
                    </TabPane> */}{" "}
            <TabPane tabId="25">
              <Row className="flex-column-reverse flex-lg-row">
                <Col
                  className="d-flex justify-content-center align-items-center"
                  lg="7"
                >
                  <div>
                    <Row>
                      <Col>
                        <h3 className="mb-0"> Caitlin Blakeley </h3>{" "}
                        <h4 className="mb-4" style={{ color: "#00C1C8" }}>
                          <small> Technology Recruiter </small>{" "}
                        </h4>{" "}
                      </Col>{" "}
                      <Col lg="6">
                        <a
                          href="mailto:caitlin.blakeley@mypeersource.com"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img alt="" className="mx-2" src={Email} />{" "}
                        </a>{" "}
                        <a
                          href="https://www.linkedin.com/in/caitlin-blakeley-183586224"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {" "}
                          <img alt="" className="mx-2" src={Linkedin} />{" "}
                        </a>{" "}
                      </Col>{" "}
                    </Row>
                    <p>
                      Caitlin is a Technology Recruiter at PeerSource. She
                      recently graduated from the University of Missouri with a
                      BA in Psychology and is excited to launch her career in
                      the technology and staffing industries. In her free time,
                      she loves spending time with her friends, family, and dog.
                      She spends the rest of her time doing digital art and
                      caring for all her houseplants.{" "}
                    </p>{" "}
                  </div>{" "}
                </Col>{" "}
                <Col className="text-center" lg="5">
                  <img
                    alt=""
                    style={{ width: "300px" }}
                    className=""
                    src={staffcaitlin}
                  />{" "}
                </Col>{" "}
              </Row>{" "}
            </TabPane>{" "}
          </TabContent>{" "}
        </div>{" "}
      </div>{" "}
    </div>
  )
}

export default Example
